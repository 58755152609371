import { USER_LIST, PROFIL_EDIT, FORFAIT_ID } from "../actions/actionsType";

const initialState = {
  userList: [],
  forfaitId: null,
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_LIST:
      return {
        ...state,
        userList: action.userList,
      };

    case FORFAIT_ID:
      return {
        ...state,
        forfaitId: action.forfaitId,
      };
    default:
      return state;
  }
};
export default reducer;
