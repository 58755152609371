import React from "react";

import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import { Trans } from "react-i18next";

export default function AlertDialog(props) {
  const [open, setOpen] = React.useState(true);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  return (
    <div>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        ContentProps={{
          "aria-describedby": "message-id",
        }}
      >
        <SnackbarContent
          style={{
            background: " #950000 0% 0% no-repeat padding-box",
            boxShadow: " 0px 3px 6px #00000029",
            opacity: "1",
          }}
          message={
            <span
              id="fauxOtpMsg"
              style={{
                fontFamily: "Montserrat",
                fontWeight: "400",
                fontSize: "15px",
                letterSpacing: 0,
                color: "#FFFFFF",
                opacity: "1",
                textAlign: "center",
              }}
            >
              <Trans i18nKey="messageErrcode" />
            </span>
          }
        />
      </Snackbar>
    </div>
  );
}
