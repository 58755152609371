import React, { Component } from "react";
import "./App.css";
import { Route, Switch } from "react-router-dom";
import TagManager from "react-gtm-module";

import { translate } from "react-i18next";
import Login from "./pages/loginPage/login";
import Otp from "./pages/Otp/otp";
import home from "./pages/home/home";
import Quiz from "./pages/quiz/quiz";
import popup from "./pages/popup/popup";
import forfait from "./pages/forfait/forfait";
import endQuiz from "./pages/endQuiz/endQuiz";
import finQuiz from "./pages/finQuiz/finQuiz";

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_IDGTM,

}; 

class App extends Component {
  render() {
    if (
      localStorage.getItem("i18nextLng") !== "fr" &&
      localStorage.getItem("i18nextLng") !== "ar"
    ) {
      localStorage.setItem("i18nextLng", "fr");
    }

    var len = localStorage.getItem("i18nextLng");
    var x = document.getElementById("content").getAttribute("lang");

    if (len !== x) {
      window.location.reload();
    }

    return (
      <div>
        <Switch>
          <Route exact path="/" component={Login} />
          <Route path="/loginOTP" component={Otp} />
          <Route path="/forfait" component={forfait} />
          <Route path="/home" component={home} />
          <Route path="/quiz" component={Quiz} />
          <Route path="/checkQuiz" component={popup} />
          <Route path="/endQuiz" component={endQuiz} />
          <Route path="/finQuiz" component={finQuiz} />
        </Switch>
      </div>
    );
  }
}
TagManager.initialize(tagManagerArgs);

export default translate("translations")(App);
