import React from "react";
import AcceuilHeader from "../acceuilHeader/acceuil";
import { Trans } from "react-i18next";
import "./popup.css";
import { connect } from "react-redux";
import { compose } from "redux";
import etoile from "../../assets/etoile.png";
import robot_sad from "../../assets/robot_sad.png";
import history from "../../history";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Link } from "react-router-dom";
import robot_happy from "../../assets/robot_happy.png";

let app = "";
class Popup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      chatListUsers: "",
      selectedOption: null,
      status: false,
      langue: localStorage.getItem("i18nextLng"),
      id: Math.random().toString(16).slice(2),
      next: null,
    };
  }
  componentDidMount() {
    const dataLayer = window.dataLayer || [];
    dataLayer.push({ event: "pageview" });
    var token = localStorage.getItem("token");
    var mobile = localStorage.getItem("num");
    var have_forfait = localStorage.getItem("forfait");
    var app = localStorage.getItem("idClient");
    if (app == "" || app == null) {
      localStorage.setItem("idClient", this.state.id);
    }
    if (
      token === null ||
      token === "" ||
      mobile === null ||
      mobile === "" ||
      have_forfait === null ||
      have_forfait === "" ||
      have_forfait == "false"
    ) {
      history.push("/");
    }
    if (this.props.QuestionsList.question == undefined) {
      history.push("/quiz");
    }
  }
  goQuiz = () => {
    history.push("/quiz");
  };
  render() {
    const { langue } = this.state;
    const testLang = langue === "fr";
    if (window && window.gaGlobal) {
      app = window.gaGlobal.vid;
    }

    return (
      <div>
        <div className="home3">
          <div
            style={{
              justifyContent: "flex-end",
              flexDirection: "row",
              display: "flex",
            }}
          >
            <div className={testLang ? "iconetoilepFr" : "iconetoilepAr"}>
              <img src={etoile} alt="description " />
            </div>

            <div className={testLang ? "pointBTotalaFR" : "pointBTotalaAR"}>
              <p className={testLang ? "pointBTAc" : "pointTBAcAr"}>
                {this.props.QuestionsAnswer.pointsAct}
              </p>
            </div>
          </div>

          <div className="div2Profil2">
            <p className={testLang ? "questionB" : "questionBAr"}>
              {this.props.QuestionsList.question}
            </p>
          </div>
          {this.props.QuestionsAnswer.statusQuestion == false &&
            (this.props.QuestionsAnswer.fauseQuestion == 1 ||
              this.props.QuestionsAnswer.fauseQuestion == 2) && (
              <div
                style={{
                  backgroundColor: "#ffffff",
                  alignItems: "center",
                  borderRadius: 15,
                  marginLeft: 40,
                  marginRight: 40,
                }}
              >
                <div
                  style={{
                    justifyContent: "center",
                    display: "flex",
                    paddingTop: "10px",
                  }}
                >
                  <img src={robot_sad} alt="description" />
                </div>
                <div className="responsesdiv">
                  <p className="resultresp">
                    <Trans i18nKey="Mauvaise_réponse" />
                  </p>
                </div>
                <div className={testLang ? "divBoutonFR" : "divBoutonAR"}>
                  <Link to="/quiz" style={{ textDecoration: "none" }}>
                    <button
                      className={testLang ? "btn" : "btnAr"}
                      type="submit"
                    >
                      <Trans i18nKey="Suivant" />
                    </button>
                  </Link>
                </div>
              </div>
            )}
          {this.props.QuestionsAnswer.statusQuestion == true && (
            <div
              style={{
                backgroundColor: "#ffffff",
                alignItems: "center",
                borderRadius: 15,
                marginLeft: 40,
                marginRight: 40,
                top: "50%",
              }}
            >
              <div
                style={{
                  justifyContent: "center",
                  display: "flex",
                  paddingTop: "10px",
                }}
              >
                <img src={robot_happy} alt="description" />
              </div>
              <div className="responsesdiv">
                <p className="resultresp">
                  <Trans i18nKey="correcte" />
                </p>
              </div>
              <div className="divBoutonFR">
                <button
                  onClick={this.goQuiz}
                  className={testLang ? "btn" : "btnAr"}
                  type="submit"
                >
                  <Trans i18nKey="Suivant" />
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.ui.isLoading,
    userList: state.userList.userList,
    QuestionsList: state.QuestionsList.QuestionsList,
    QuestionsAnswer: state.QuestionsAnswer.QuestionsAnswer,
    tricheCustomer: state.tricheCustomer.tricheCustomer,
  };
};

export default compose(connect(mapStateToProps))(Popup);
