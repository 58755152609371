import * as io from "socket.io-client";
import events from "events";
import history from "../../../history";

class SocketServer {
  socket = null;
  eventEmitter = new events.EventEmitter();
  _id = null;

  establishSocketConnection = (token, mobile, app) => {
    try {
      this.socket = io(`${process.env.REACT_APP_WEBS}`, {
        query: { token: token, mobile: mobile, app: app },
      });
    } catch (error) {
      alert(`Something went wrong; Can't connect to socket server`);
    }
  };
  verifUserDesinscription(data, app) {
    var token = localStorage.getItem("token");
    var mobile = localStorage.getItem("num");
    var langue = localStorage.getItem("i18nextLng");

    if (this.socket == null && token != null && token != "")
      this.establishSocketConnection(token, mobile, app);

    if (token == null || token == "" || token == undefined) {
      localStorage.clear();
      localStorage.setItem("i18nextLng", langue);
      sessionStorage.clear();

      history.push("/");
    } else {
      this.socket.emit("desinscription-user-quiz", data);
      this.socket.on("desinscription-user-verif-quiz", (data) => {
        this.eventEmitter.emit("desinscription-user-verif-quiz", data);
      });
    }
  }
  verifUserLang(data, app) {
    var token = localStorage.getItem("token");
    var mobile = localStorage.getItem("num");
    var langue = localStorage.getItem("i18nextLng");

    if (this.socket == null && token != null && token != "")
      this.establishSocketConnection(token, mobile, app);
    if (token == null || token == "" || token == undefined) {
      localStorage.clear();
      localStorage.setItem("i18nextLng", langue);
      sessionStorage.clear();

      history.push("/");
    } else {
      this.socket.emit("lang-user-quiz", data);
      this.socket.on("lang-user-quiz-response", (data) => {
        this.eventEmitter.emit("lang-user-quiz-response", data);
      });
    }
  }
  logout(data, app) {
    var token = localStorage.getItem("token");
    var mobile = localStorage.getItem("num");
    var langue = localStorage.getItem("i18nextLng");

    if (this.socket == null && token != null && token != "")
      this.establishSocketConnection(token, mobile, app);
    if (token == null || token == "" || token == undefined) {
      localStorage.clear();
      localStorage.setItem("i18nextLng", langue);
      sessionStorage.clear();

      history.push("/");
    } else {
      this.socket.emit("logout-quiz", data);
      this.socket.on("logout-response-quiz", (data) => {
        this.eventEmitter.emit("logout-response-quiz", data);
      });
    }
  }
}
export default new SocketServer();
