import {
  LOGIN_FAILED,
  LOGIN_SUCCESS,
  LOGIN_CODE_OTP,
  LOGIN_JAW,
} from "../actions/actionsType";

const initialState = {
  loginFailed: "",
  loginSucces: "",
  loginOtpCode: "",
  loginJaw: true,
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_FAILED:
      return {
        ...state,
        loginFailed: action.loginFailed,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        loginSucces: action.loginSucces,
      };
    case LOGIN_CODE_OTP:
      return {
        ...state,
        loginOtpCode: action.loginOtpCode,
      };

    case LOGIN_JAW:
      return {
        ...state,
        loginJaw: action.loginJaw,
      };
    default:
      return state;
  }
};
export default reducer;
