/** @format */

import React from "react";
import robot_forfait from "../../assets/robot_forfait.png";
import "./index.css";
import history from "../../history";
import { connect } from "react-redux";
import { compose } from "redux";
import logo from "../../assets/logo-jawhara-mobile.svg";
import logott from "../../assets/logott.svg";
import socketServer from "../../store/actions/utils/socketServer";
import {
  addForfait,
  msgErrStatus,
  msgStatusTrue,
  GetmodifLogin,
} from "../../store/actions";
import { Trans } from "react-i18next";
import Pop from "./popUp";
import PopError1 from "./popupError1";
import PopError2 from "./popupError2";
import i18n from "../../i18n";
import TagManager from "react-gtm-module";
class Forfait extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      forfait: "none",
      openForfait: false,
      open: false,
      errStatusL: true,
      langue: localStorage.getItem("i18nextLng"),
      id: Math.random().toString(16).slice(2),
    };
  }

  componentDidMount() {
    const dataLayer = window.dataLayer || [];
    dataLayer.push({ event: "pageview" });
    var app = localStorage.getItem("idClient");
    if (app == "" || app == null) {
      localStorage.setItem("idClient", this.state.id);
    }
    var token = localStorage.getItem("token");
    var mobile = localStorage.getItem("num");
    var have_forfait = localStorage.getItem("forfait");
    var app = localStorage.getItem("idClient");

    if (
      (mobile != null || mobile != "") &&
      (token != null || token != "") &&
      app != ""
    ) {
      socketServer.verifUserDesinscription({ mobile: mobile }, app);
      socketServer.eventEmitter.on(
        "desinscription-user-verif-quiz",
        this.UserDesinscription,
      );
    }

    if (
      (token === null || token === "") &&
      (mobile === null || mobile === "") &&
      (have_forfait === null || have_forfait === "" || have_forfait == "false")
    ) {
      history.push("/");
    }
    if (
      token !== null &&
      token !== "" &&
      mobile !== null &&
      mobile !== "" &&
      have_forfait !== null &&
      have_forfait !== "" &&
      have_forfait == "true"
    ) {
      history.push("/home");
    }
    socketServer.verifUserLang({ mobile: mobile }, app);
    socketServer.eventEmitter.on("lang-user-quiz-response", this.UserLang);
    this.props.fetchDataLogin();
  }
  componentWillUnmount() {
    // socketServer.socket.off("desinscription-user-verif-quiz");
  }
  componentWillReceiveProps(prevprops) {
    if (this.props.msgStatusTrue !== prevprops.msgStatusTrue) {
      localStorage.setItem("forfait", true);
      history.push("/home");
    }
  }
  UserLang = (socketResponse) => {
    var len = localStorage.getItem("i18nextLng");
    var lang = socketResponse.lang_app;
    if (len !== lang) {
      localStorage.setItem("i18nextLng", socketResponse.lang_app);
    }
  };
  UserDesinscription = (socketResponse) => {
    if (
      socketResponse !== undefined &&
      socketResponse.have_forfait !== undefined &&
      socketResponse.have_forfait == true
    ) {
      localStorage.setItem("forfait", socketResponse.have_forfait);
      //history.push("/home");
    }
  };

  componentWillMount() {
    this.props.fetchError(true);
    this.props.fetchStatusTrue(false);
  }

  async SelectForfaitChange(value) {
    await this.setState({ forfait: value });
  }
  goTo = () => {
    history.goBack();
  };
  deconnecter = () => {
    var mobile = localStorage.getItem("num");
    var app = localStorage.getItem("idClient");
    var langue = this.state.langue;

    const data = {
      mobile: mobile,
    };

    // socketServer.socket.off("desinscription-user-verif-quiz")
    // socketServer.logout(data, app);

    localStorage.clear();
    sessionStorage.clear();
    localStorage.setItem("i18nextLng", langue);

    history.push("/");
  };
  ValidForfait = () => {
    var mobile = localStorage.getItem("num");
    if (mobile === null) {
      history.push("/");
    } else {
      const data = {
        mobile: mobile,
        achatPack: true,
      };
      this.props.ChoisirFofait(data);
    }
  };

  render() {
    const { langue } = this.state;
    const testLang = langue === "fr";
    let x = localStorage.getItem("id_ga");
    const tagManagerArgs = {
      gtmId: process.env.REACT_APP_IDGTM,

      dataLayer: {
        userId: x,
        userProject: "Forfait_page",
        event: "GAID_Event",
      },
    };
    TagManager.initialize(tagManagerArgs);
    return (
      <div className="home" id="pageForfait">
        <div style={{ display: "flex", flexDirection: "row" }}>
          {/* <div
            style={{ width: "100%", border: " 1px solid", color: "#FFFFFF" }}
          ></div> */}
        </div>
        <div className="heightLogo"></div>
        <div className="imgLogo1">
          <img
            src={logo}
            style={{ height: "70%", width: "270px" }}
            alt="description"
          />
        </div>
        <div className="logott" dir={"ltr"}>
          <h2 className={testLang ? "logitttext" : "logitttext"}>
            <Trans i18nKey="Bytt" />
          </h2>
          <img src={logott} alt="logo tt " style={{ width: "20%" }} />
        </div>

        <div className="divFormStep13">
          <div className="divStep6">
            {this.props.userList.status == false &&
            (this.props.userList.fauseQuestion == 3 ||
              this.props.userList.pointsActuel == 0) ? (
              <div className="div5Profi1">
                <label className={testLang ? "labelStep22" : "labelStep22Ar"}>
                  <Trans i18nKey="forfait" />
                </label>
              </div>
            ) : (
              <div className="div5Profi1">
                <label className={testLang ? "labelStep22" : "labelStep22Ar"}>
                  <Trans i18nKey="forfait1" />
                </label>
              </div>
            )}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                paddingTop: "30px",
              }}
            >
              <img
                src={robot_forfait}
                style={{ width: "180px" }}
                alt="description"
              />
            </div>
            {this.props.userList.status == false &&
            (this.props.userList.fauseQuestion == 3 ||
              this.props.userList.pointsActuel == 0) ? (
              <div>
                <div className="div5Profi1">
                  <p className="ressayerF1">
                    <Trans i18nKey={testLang ? "Ressayer1" : "Ressayer4"} />
                  </p>
                </div>

                {testLang && (
                  <div className="div5Profi11">
                    <p className="ressayerF1">
                      <Trans i18nKey="Ressayer2" />
                    </p>
                  </div>
                )}

                <div className="div5Profil2 d-block">
                  <p className="ressayerF1">
                    <Trans i18nKey="Ressayer3" />
                  </p>
                </div>
              </div>
            ) : (
              <div className="div5Profile2 d-block">
                <p className="ressayerF1">
                  <Trans i18nKey="Ressaye3" />
                </p>
                <p className="ressayerF1">
                  {" "}
                  <Trans i18nKey="footertext_acheterforfait" />
                </p>
              </div>
            )}
            <div className="divBouton11">
              <button
                className={testLang ? "btnForfait" : "btnForfaitAr"}
                disabled={this.props.isLoadingForfait}
                onClick={this.ValidForfait}
                id={
                  this.props.userList.status == false &&
                  (this.props.userList.fauseQuestion == 3 ||
                    this.props.userList.pointsActuel == 0)
                    ? "achatForfaitBtn"
                    : "inscriptionForfaitBtn"
                }
                //type="submit"
              >
                <Trans i18nKey="acheter" />
              </button>
            </div>
            {this.props.isLoading && (
              //<CircularProgress size={20} style={{ color: "#FF9D00",   marginRight: "50%",marginLeft: "50%"} } />
              <spam
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "baseline",
                }}
              >
                <p class="CeC">
                  <Trans i18nKey="Chargement" />
                </p>
                <div class="point"></div>
                <div class="point1"></div>
                <div class="point2"></div>
              </spam>
            )}
            <div className="divBouton2">
              <p
                className="btnquitterForfait"
                onClick={this.deconnecter}
                id="quitForfaitBtn"
                type="submit"
              >
                <Trans i18nKey="quitter" />
              </p>
            </div>
            {this.props.msgErrStatus === false &&
              this.props.allData.message !== undefined && (
                <div>
                  {this.props.allData.message ==
                    "Votre demande ne peut pas être traitée maintenant, veuillez réessayer plus tard." && (
                    <PopError1 />
                  )}
                  {this.props.allData.message ==
                    "Cher Client, votre solde est insuffisant." && <Pop />}

                  {this.props.allData.message ==
                    "Votre demande ne peut pas être traitée , veuillez vérifier votre service ou votre abonnement internet." && (
                    <PopError2 />
                  )}
                </div>
              )}
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    isLoader: state.ui.isLoader,
    isLoading: state.ui.isLoading,
    msgErrStatus: state.msgErrStatus.msgErrStatus,
    msgrrorFr: state.msgrrorFr.msgrrorFr,
    msgrrorAr: state.msgrrorAr.msgrrorAr,
    msgStatusTrue: state.msgStatusTrue.msgStatusTrue,
    allData: state.allData.allData,
    userList: state.userList.userList,
    isLoadingForfait: state.isLoadingForfait.isLoadingForfait,
  };
};
const mapDispatchToProps = (dispatch, ownProps) => ({
  fetchDataLogin: () => {
    dispatch(GetmodifLogin());
  },
  ChoisirFofait: (data) => dispatch(addForfait(data)),
  fetchError: (data) => dispatch(msgErrStatus(data)),
  fetchStatusTrue: (data) => dispatch(msgStatusTrue(data)),
});
export default compose(connect(mapStateToProps, mapDispatchToProps))(Forfait);
