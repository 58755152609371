import { uiStartLoader, uiStopLoader } from "./index";
import { uiStartLoading, uiStopLoading } from "./index";

import {
  FORFAIT_LIST,
  MSG_ERROR_FR,
  MSG_ERROR_AR,
  MSG_ERROR_STATUS,
  MSG_ERROR_GRATUIT,
  MSG_TRUE,
  MSG_GRATUIT_TRUE,
  ALL_DATA,
  RES_FALSE_AR,
  RES_FALSE_FR,
  MSG_TRUE_GRATUIT,
  STOP_LOADING,
  START_LOADING,
} from "./actionsType";
import { GetmodifLogin } from "./modifProfil";
import { QuestionsAnswer } from "./question";

import history from "../../history";

export const getCustomerForfait = (data) => {
  return (dispatch) => {
    dispatch(uiStartLoader());
    fetch(`${process.env.REACT_APP_DOMAIN}/ajax/customer_groups`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .catch((err) => {
        dispatch(uiStopLoader());
      })
      .then((res) => res.json())
      .then((res) => {
        dispatch(forfaitList(res));
      });
  };
};

export const addForfaitGratuit = (data) => {
  return (dispatch) => {
    dispatch(uiStartLoading());
    dispatch(startLoading());
    dispatch(msgErrStatusGratuit(""));
    dispatch(resFalseFr(""));
    dispatch(resFalseAr(""));
    dispatch(msgTrueGratuit(""));

    fetch(`${process.env.REACT_APP_DOMAIN}/ajax/quiz/unsubscribe`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .catch((err) => {
        dispatch(uiStopLoading());
        dispatch(stopLoading());
      })
      .then((res) => res.json())
      .then((res) => {
        dispatch(uiStopLoading());
        dispatch(stopLoading());

        if (res.status === false) {
          dispatch(msgErrStatusGratuit(res.status));
          dispatch(resFalseFr(res.message));
          dispatch(resFalseAr(res.messageAR));
        }
        if (res.status === true) {
          // dispatch(GetmodifLogin());

          dispatch(msgTrueGratuit(res.status));
          dispatch(msgTrueGratuitFr(res.message));
        }
      });
  };
};

export const addForfait = (data) => {
  return (dispatch) => {
    dispatch(uiStartLoading());
    dispatch(startLoading());
    dispatch(msgErrStatus(true));
    dispatch(msgStatusTrue(false));
    dispatch(msgrrorFr(""));
    dispatch(msgrrorAr(""));

    fetch(`${process.env.REACT_APP_DOMAIN}/ajax/quiz/register`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .catch((err) => {
        dispatch(uiStopLoading());
        dispatch(stopLoading());
      })
      .then((res) => res.json())
      .then((res) => {
        dispatch(uiStopLoading());
        dispatch(stopLoading());
        if (
          res.status == false &&
          res.message == "Vous etes déjà inscrit au service Jawhara Quiz."
        ) {
          dispatch(GetmodifLogin());
          dispatch(msgStatusTrue(true));
          dispatch(msgErrStatus(res.status));
          dispatch(
            msgrrorFr(
              res.message == "Vous etes déjà inscrit au service Jawhara Quiz."
            )
          );
          dispatch(msgrrorAr(res.messageAR));
        } else if (
          res.status === false &&
          (res.message ==
            "Votre demande ne peut pas être traitée , veuillez vérifier votre service ou votre abonnement internet." ||
            res.message ==
              "Votre demande ne peut pas être traitée maintenant." ||
            res.message ==
              "Votre demande ne peut pas être traitée maintenant, veuillez réessayer plus tard.")
        ) {
          localStorage.clear();
          sessionStorage.clear();
          history.push("/");
        } else if (res.status === false) {
          dispatch(msgErrStatus(res.status));
          dispatch(msgrrorFr(res.message));
          dispatch(msgrrorAr(res.messageAR));
        }
        if (res.status === true) dispatch(GetmodifLogin());

        dispatch(allData(res));
        dispatch(msgStatusTrue(res.status));
        dispatch(QuestionsAnswer({}));
      });
  };
};

export const forfaitList = (value) => {
  return {
    type: FORFAIT_LIST,
    forfaitList: value,
  };
};

export const startLoading = () => {
  return {
    type: START_LOADING,
  };
};
export const stopLoading = () => {
  return {
    type: STOP_LOADING,
  };
};
export const msgrrorFr = (value) => {
  return {
    type: MSG_ERROR_FR,
    msgrrorFr: value,
  };
};

export const msgrrorAr = (value) => {
  return {
    type: MSG_ERROR_AR,
    msgrrorAr: value,
  };
};
export const msgErrStatus = (value) => {
  return {
    type: MSG_ERROR_STATUS,
    msgErrStatus: value,
  };
};
export const msgErrStatusGratuit = (value) => {
  return {
    type: MSG_ERROR_GRATUIT,
    msgErrStatusGratuit: value,
  };
};
export const msgStatusTrue = (value) => {
  return {
    type: MSG_TRUE,
    msgStatusTrue: value,
  };
};
export const msgTrueGratuit = (value) => {
  return {
    type: MSG_GRATUIT_TRUE,
    msgTrueGratuit: value,
  };
};
export const allData = (value) => {
  return {
    type: ALL_DATA,
    allData: value,
  };
};

export const resFalseFr = (value) => {
  return {
    type: RES_FALSE_FR,
    resFalseFr: value,
  };
};

export const resFalseAr = (value) => {
  return {
    type: RES_FALSE_AR,
    resFalseAr: value,
  };
};
export const msgTrueGratuitFr = (value) => {
  return {
    type: MSG_TRUE_GRATUIT,
    msgTrueGratuitFr: value,
  };
};
