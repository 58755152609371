import {
  LOGIN_INPUT_SUCCES,
  LOGIN_INPUT_TRUE,
  CODE_INVALID_OTP,
  OTP_EXPIRE,
} from "../actions/actionsType";

const initialState = {
  InputOtpValid: false,
  InputOtpTrue: "",
  CodeInvalidOtp: true,
  OtpExpire: true,
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_INPUT_SUCCES:
      return {
        ...state,
        InputOtpValid: action.InputOtpValid,
      };
    case LOGIN_INPUT_TRUE:
      return {
        ...state,
        InputOtpTrue: action.InputOtpTrue,
      };
    case CODE_INVALID_OTP:
      return {
        ...state,
        CodeInvalidOtp: action.CodeInvalidOtp,
      };
    case OTP_EXPIRE:
      return {
        ...state,
        OtpExpire: action.OtpExpire,
      };
    default:
      return state;
  }
};
export default reducer;
