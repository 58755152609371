import React, { useState, useEffect } from "react";
import "./timer.css";
const Timer = (props) => {
  const [seconds, setseconds] = useState(20);
  const [interval, setinterval] = useState(0);
  const tick = () => {
    setseconds((seconds) => seconds - 1);
  };

  useEffect(() => {
    setinterval(setInterval(() => tick(), 1000));
  }, []);
  useEffect(() => {
    if (seconds == 0) {
      props.timout();
      clearInterval(interval);
    }
  }, [seconds]);
  useEffect(() => {
    setseconds(20);
  }, [props.relaunch]);

  const formatTime = (secs) => {
    let hours = Math.floor(secs / 3600);
    let minutes = Math.floor(secs / 60) % 60;
    let seconds = secs % 60;

    return [hours, minutes, seconds]
      .map((v) => ("" + v).padStart(2, "0"))
      .filter((v, i) => v !== "00" || i > 0)
      .join(":");
  };

  return (
    <div className="app">
      <main className="main">
        <div className="display">
          <div className="segments">
            <span className="mins">{formatTime(seconds)}</span>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Timer;
