import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import "./login.css";
import { Trans } from "react-i18next";

export default function AlertDialog(props) {
  const [open, setOpen] = React.useState(true);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  return (
    <div>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        ContentProps={{
          "aria-describedby": "message-id",
        }}
      >
        <SnackbarContent
          style={{
            background: " #5BA887",
            boxShadow: " 0px 3px 6px #00000029",
            opacity: "1",
          }}
          message={
            <span
              id="numNonTTMsg"
              className={props.lang ? "msgerrFR" : "msgerrAR"}
            >
              <Trans i18nKey="messageErrNT" />
            </span>
          }
        />
      </Snackbar>
    </div>
  );
}
