import React from "react";
import leftArrow from "../../assets/left-arrow-1.svg";
import phone from "../../assets/smartphone.svg";
import "./otp.css";
import history from "../../history";
import OTPInput from "otp-input-react";
import { connect } from "react-redux";
import { compose } from "redux";
import {
  loginOtpCodeInput,
  loginOtp,
  CodeInvalidOtp,
  OtpExpire,
} from "../../store/actions";
import Pop from "./popUp";
import PopDeali from "./popUpdelai";
import { Trans } from "react-i18next";
import { isMobileSafari } from "react-device-detect";
import TagManager from "react-gtm-module";
class Otp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      otp: "",
      langue: localStorage.getItem("i18nextLng"),
    };
  }

  componentDidMount() {
    const dataLayer = window.dataLayer || [];
    dataLayer.push({ event: "pageview" });
    var token = localStorage.getItem("token");
    var mobile = localStorage.getItem("num");
    var have_forfait = localStorage.getItem("forfait");

    // if (
    //   token !== null &&
    //   token !== "" &&
    //   mobile !== null &&
    //   mobile !== "" &&
    //   have_forfait !== null &&
    //   have_forfait !== "" &&
    //   have_forfait == "true"
    // ) {
    //   history.push("/home");
    // }
    // else
    if (
      token !== null &&
      token !== "" &&
      mobile !== null &&
      mobile !== "" &&
      have_forfait !== null &&
      have_forfait !== "" &&
      have_forfait == "false"
    ) {
      history.push("/forfait");
    } else if (mobile == null || mobile == "") {
      history.push("/");
    }
  }

  handleSubmit = () => {
    this.props.OtpExpireAc(true);
    this.props.CodeInvalidOtpAc(true);
    const data = {
      mobile: localStorage.getItem("num"),
      otpCodeInput: this.state.otp,
      otpCodeChif: sessionStorage.getItem("otpCodeChif"),
    };
    this.props.fetchData(data);
  };
  goTo = () => {
    this.props.OtpExpireAc(true);
    this.props.CodeInvalidOtpAc(true);
    history.push("/");
  };

  handleOtpChange = (otp) => {
    this.setState({ otp });
  };

  handleRenvoyer = () => {
    this.props.OtpExpireAc(true);
    this.props.CodeInvalidOtpAc(true);
    this.setState({
      otp: "",
    });
    var len = localStorage.getItem("num");
    const data = {
      mobile: len,
    };
    this.props.fetchDataCode(data);
  };

  render() {
    const { langue } = this.state;
    const testLang = langue === "fr";
    let x = localStorage.getItem("id_ga");
    const tagManagerArgs = {
      gtmId: process.env.REACT_APP_IDGTM,

      dataLayer: {
        userId: x,
        userProject: "loginOTP_page",
        event: "GAID_Event",
      },
    };
    TagManager.initialize(tagManagerArgs);
    return (
      <div className="home" id="pageloginOTP">
        <img
          src={leftArrow}
          onClick={this.goTo}
          alt="description"
          className={testLang ? "imgleft" : "imgright"}
        />
        <div className="divOtp">
          <img src={phone} className="imgphone" />
          <p className={testLang ? "textSaisieCode" : "textSaisieCodeAr"}>
            <Trans i18nKey="Sais_code_veri" />
          </p>

          <p className={testLang ? "styleNum" : "styleNumAr"}>
            {localStorage.getItem("num")}
          </p>
          {isMobileSafari ? (
            <div className={langue === "fr" ? "divcodeOtp" : "divcodeAR"}>
              <OTPInput
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                value={this.state.otp}
                onChange={this.handleOtpChange}
                autoFocus
                OTPLength={6}
                otpType="number"
                disabled={false}
                inputStyles={{
                  margin: "1%",
                  fontSize: "20px",
                  width: "10%",
                  height: 40,
                  borderRadius: 4,
                  border: "1px solid rgba(0,0,0,0.3)",
                  color: "rgba(255, 157, 0, 1)",
                  outline: "none",
                }}
              />
            </div>
          ) : (
            <div className={langue === "fr" ? "divcodeOtp" : "divcodeAR"}>
              <OTPInput
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                value={this.state.otp}
                onChange={this.handleOtpChange}
                autoFocus
                OTPLength={6}
                otpType="number"
                disabled={false}
                inputStyles={{
                  margin: "1%",
                  fontSize: "2rem",
                  width: "10%",
                  height: 40,
                  borderRadius: 4,
                  border: "1px solid rgba(0,0,0,0.3)",
                  color: "rgba(255, 157, 0, 1)",
                  outline: "none",
                }}
              />
            </div>
          )}
          <p className={testLang ? "textRenvoiCode" : "textRenvoiCodeAr"}>
            <Trans i18nKey="Recu_Pa_code" />
          </p>
          <p
            className={testLang ? "textRenvoyer" : "textRenvoyerAr"}
            onClick={this.handleRenvoyer}
            id="renvoyerOtpBtn"
          >
            <Trans i18nKey="Renvoyer" />
          </p>
          <div className="divBoutonOtp">
            <button
              className={testLang ? "btn" : "btnAr"}
              onClick={this.handleSubmit}
              id="enterOtpBtn"
            >
              <Trans i18nKey="Suivant" />
            </button>
          </div>
        </div>
        {this.props.isLoading && (
          <spam
            style={{
              display: "flex",
              width: "332px",
              justifyContent: "space-around",
              alignItems: "baseline",
            }}
          >
            <span class="CeC">
              <Trans i18nKey="Chargement" />{" "}
            </span>
            <div class="point"></div>
            <div class="point1"></div>
            <div class="point2"></div>
          </spam>
        )}

        {this.props.CodeInvalidOtp === false && (
          <Pop lang={localStorage.getItem("i18nextLng") === "fr"} />
        )}
        {this.props.OtpExpire === false && (
          <PopDeali lang={localStorage.getItem("i18nextLng") === "fr"} />
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    isLoading: state.ui.isLoading,
    InputOtpValid: state.InputOtpValid.InputOtpValid,
    CodeInvalidOtp: state.CodeInvalidOtp.CodeInvalidOtp,
    loginSucces: state.loginSucces.loginSucces,
    OtpExpire: state.OtpExpire.OtpExpire,
  };
};
const mapDispatchToProps = (dispatch, ownProps) => ({
  fetchData: (data) => {
    dispatch(loginOtpCodeInput(data));
  },
  fetchDataCode: (data) => {
    dispatch(loginOtp(data));
  },
  OtpExpireAc: (data) => {
    dispatch(OtpExpire(data));
  },
  CodeInvalidOtpAc: (data) => {
    dispatch(CodeInvalidOtp(data));
  },
});
export default compose(connect(mapStateToProps, mapDispatchToProps))(Otp);
