import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Menu from "../../assets/menu.svg";
import robot_quiz from "../../assets/robot_quiz.png";
import robot_bonus from "../../assets/robot_bonus.png";
import robot_bonus1 from "../../assets/robot_bonus1.png";
import etoile from "../../assets/etoile.png";
import "./acceuil.css";
import history from "../../history";
import { connect } from "react-redux";
import { compose } from "redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import TagManager from "react-gtm-module";
import socketServer from "../../store/actions/utils/socketServer";

class Accueil extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      langue: localStorage.getItem("i18nextLng"),
      id: Math.random().toString(16).slice(2),
    };
  }

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };
  handleClick() {
    history.push("/profil");
  }
  componentDidMount() {
    var token = localStorage.getItem("token");
    var mobile = localStorage.getItem("num");
    var have_forfait = localStorage.getItem("forfait");
    var app = localStorage.getItem("idClient");
    if (app == "" || app == null) {
      localStorage.setItem("idClient", this.state.id);
    }
    // if (
    //   (mobile != null || mobile != "") &&
    //   (token != null || token != "") &&
    //   app != ""
    // ) {
    //   socketServer.verifUserDesinscription({ mobile: mobile }, app);
    //   socketServer.eventEmitter.on(
    //     "desinscription-user-verif-quiz",
    //     this.UserDesinscription
    //   );
    // }
    if (
      token === null ||
      token === "" ||
      mobile === null ||
      mobile === "" ||
      have_forfait === null ||
      have_forfait === "" ||
      have_forfait == "false"
    ) {
      history.push("/");
    }
  }
  componentWillUnmount() {
    // socketServer.socket.off("desinscription-user-verif-quiz");
  }
  // UserDesinscription = (socketResponse) => {
  //   if (
  //     socketResponse !== undefined &&
  //     socketResponse.have_forfait !== undefined &&
  //     socketResponse.have_forfait == false
  //   ) {
  //     localStorage.setItem("forfait", socketResponse.have_forfait);
  //     // history.push("/forfait");
  //   }
  // };
  handleCloseDialog = (value) => {
    this.setState({ open: false });
  };
  handleCloseMenu = () => {
    this.setState({ open: false });
  };
  componentDidUpdate(prevProps) {
    if (this.props.userList !== prevProps.userList) {
      this.setState({
        forfait_id: this.props.userList.forfait_id,
        id_GA: this.props.userList.id_GA,
      });
      localStorage.setItem("id_ga", this.props.userList.id_GA);
    }
  }
  render() {
    const { langue } = this.state;
    const testLang = langue === "fr";

    return (
      <div>
        {this.props.userList !== "" && (
          <div
            style={{
              flexDirection: "row",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            {this.props.QuestionsList.point == 5 && (
              <div className="imgProfilList">
                <img src={robot_quiz} alt="description " />
              </div>
            )}
            {this.props.QuestionsList.point == 10 && (
              <div className="imgProfilList">
                <img
                  src={testLang ? robot_bonus : robot_bonus1}
                  alt="description "
                />
              </div>
            )}
            <div
              style={{
                justifyContent: "center",
                flexDirection: "row",
                display: "flex",
                marginRight: "5%",
                marginTop: "2%",
              }}
            >
              <div className={testLang ? "iconetoileFr" : "iconetoileAr"}>
                <img src={etoile} alt="description " />
              </div>
              <div className={testLang ? "pointBTotalFR" : "pointBTotalAR"}>
                <p className={testLang ? "pointTAc" : "pointAcTAr"}>
                  {this.props.QuestionsAnswer.pointsAct}
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.ui.isLoading,
    userList: state.userList.userList,
    QuestionsList: state.QuestionsList.QuestionsList,
    QuestionsAnswer: state.QuestionsAnswer.QuestionsAnswer,
  };
};

export default compose(connect(mapStateToProps))(Accueil);
