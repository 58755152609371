import { uiStartLoaderSlider, uiStopLoaderSlider } from "./index";
import { HOME_SLIDER } from "./actionsType";

export const getHomeSlider = () => {
  var token = localStorage.getItem("token");
  return (dispatch) => {
    dispatch(uiStartLoaderSlider());
    fetch(`${process.env.REACT_APP_DOMAIN}/ajax/quiz/silders/images`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-access-token": token,
      },
    })
      // .catch(err => {
      //   dispatch(uiStopLoaderSlider());
      // })
      .then((res) => res.json())
      .then((res) => {
        dispatch(uiStopLoaderSlider());
        dispatch(HomeSlider(res.data));
      });
  };
};

export const HomeSlider = (value) => {
  return {
    type: HOME_SLIDER,
    HomeSlider: value,
  };
};
