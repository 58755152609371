export const UI_START_LOADING = "UI_START_LOADING";
export const UI_STOP_LOADING = "UI_STOP_LOADING";
export const UI_START_LOADER = "UI_START_LOADER";
export const UI_STOP_LOADER = "UI_STOP_LOADER";
export const UI_STOP = "UI_STOP";
export const UI_START = "UI_START";
export const HOME_SLIDER = "HOME_SLIDER";
export const STOP_LOADING = "STOP_LOADING";
export const ACTUALITE_LIST = "ACTUALITE_LIST";
export const CATEGORIE_LIST = "CATEGORIE_LIST";
export const NEWS_LIST = "NEWS_LIST";
export const NAME_VALUE = "NAME_VALUE";
export const FORFAIT_LIST = "FORFAIT_LIST";
export const USER_REGISTER = "USER_REGISTER";
export const NAME_DATA = "NAME_DATA";
export const TEL_DATA = "TEL_DATA";
export const ERR_NAME_DATA = "ERR_NAME_DATA";
export const ADDRESS_DATA = "ADDRESS_DATA";
export const NOM_DATA = "NOM_DATA";
export const ADDRESS_DATA_ADDRESS = "ADDRESS_DATA_ADDRESS";
export const ERR_TEL_LENGHT = "ERR_TEL_LENGHT";
export const LOGIN_FAILED = "LOGIN_FAILED";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const ERR_FORFAIT_Date = "ERR_FORFAIT_Date";
export const FORFAIT_Date = "FORFAIT_Date";
export const LOGIN_CODE_OTP = "LOGIN_CODE_OTP";
export const LOGIN_INPUT_SUCCES = "LOGIN_INPUT_SUCCES";
export const LOGIN_INPUT_TRUE = "LOGIN_INPUT_TRUE";
export const CODE_INVALID_OTP = "CODE_INVALID_OTP";
export const OTP_EXPIRE = "OTP_EXPIRE";
export const USER_LIST = "USER_LIST";
export const PROFIL_EDIT = "PROFIL_EDIT";
export const CONFIRM_DELETE = "CONFIRM_DELETE";
export const FORFAIT_ID = "FORFAIT_ID";
export const DELETE_IMAGE = "DELETE_IMAGE";
export const MSG_ERROR_AR = "MSG_ERROR_AR";
export const MSG_ERROR_FR = "MSG_ERROR_FR";
export const MSG_ERROR_STATUS = "MSG_ERROR_STATUS";
export const MSG_ERROR_GRATUIT = "MSG_ERROR_GRATUIT";
export const MSG_TRUE = "MSG_TRUE";
export const MSG_GRATUIT_TRUE = "MSG_GRATUIT_TRUE";
export const ALL_DATA = "ALL_DATA";
export const RES_FALSE_FR = "RES_FALSE_FR";
export const RES_FALSE_AR = "RES_FALSE_AR";
export const PLAY_FM = "PLAY_FM";
export const AUDIO = "AUDIO";
export const ALL_USERS_CHATT = "ALL_USERS_CHATT";
export const SUCCESS_DELETE = "SUCCESS_DELETE";
export const SUCCESS_ENVOIE = "SUCCESS_ENVOIE";
export const INVIT = "INVIT";
export const HAS_MORE_FALSE = "HAS_MORE_FALSE";
export const NEWS_LIST_MORE = "NEWS_LIST_MORE";
export const NEWS_LIST_MORE_DATA = "NEWS_LIST_MORE_DATA";
export const NEWS_FILTER = "NEWS_FILTER";
export const MORE_RECHERCHE = "MORE_RECHERCHE";
export const MORE_CATEGORIE = "MORE_CATEGORIE";
export const MORE_FILTER_Categ = "MORE_FILTER_Categ";
export const HASMORE = "HASMORE";
export const CHATMORE = "CHATMORE";
export const SALUT = "SALUT";
export const NOINVIT = "NOINVIT";
export const BLOCKED = "BLOCKED";
export const UI_STOP_INVI = "UI_STOP_INVI";
export const UI_START_INVI = "UI_START_INVI";
export const LOGIN_JAW = "LOGIN_JAW";
export const MSG_TRUE_GRATUIT = "MSG_TRUE_GRATUIT";
export const UI_START_FOOTER = "UI_START_FOOTER";
export const UI_STOP_FOOTER = "UI_STOP_FOOTER";
export const FOOTER_SLIDER = "FOOTER_SLIDER";
export const UI_START_SLIDER = "UI_START_SLIDER";
export const UI_STOP_SLIDER = "UI_STOP_SLIDER";
export const QUESTIONS_LIST = "QUESTIONS_LIST";
export const QUESTIONS_ANSWER = "QUESTIONS_ANSWER";
export const MSG_VERIF = "MSG_VERIF";
export const TRICHE_CUSTOMER = "TRICHE_CUSTOMER";
export const START_LOADING = "START_LOADING";
