import { createStore, combineReducers, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";
import isLoading from "./reducers/ui";
import loaderInvi from "./reducers/ui";
import forfaitList from "./reducers/forfait";
import startLoading from "./reducers/forfait";
import userRegister from "./reducers/inscription";
import nameData from "./reducers/inscription";
import ErrAddressDate from "./reducers/inscription";
import ErrTelData from "./reducers/inscription";
import ErrNameDate from "./reducers/inscription";
import nomData from "./reducers/inscription";
import addresData from "./reducers/inscription";
import ErrTelDataLenght from "./reducers/inscription";
import loginSucces from "./reducers/loginOtp";
import loginFailed from "./reducers/loginOtp";
import ErrForfaitDate from "./reducers/inscription";
import loginOtpCode from "./reducers/loginOtp";
import InputOtpValid from "./reducers/login";
import InputOtpTrue from "./reducers/login";
import CodeInvalidOtp from "./reducers/login";
import OtpExpire from "./reducers/login";
import msgrrorFr from "./reducers/forfait";
import msgrrorAr from "./reducers/forfait";
import msgErrStatus from "./reducers/forfait";
import msgErrStatusGratuit from "./reducers/forfait";
import isLoadingForfait from "./reducers/forfait";
import msgStatusTrue from "./reducers/forfait";
import msgTrueGratuit from "./reducers/forfait";
import allData from "./reducers/forfait";
import resFalseFr from "./reducers/forfait";
import resFalseAr from "./reducers/forfait";
import loginJaw from "./reducers/loginOtp";
import loader from "./reducers/ui";
import msgTrueGratuitFr from "./reducers/forfait";
import userList from "./reducers/modifProfil";
import editDataProfil from "./reducers/modifProfil";
import forfaitId from "./reducers/modifProfil";
import loaderFooter from "./reducers/ui";
import loaderSlider from "./reducers/ui";
import HomeSlider from "./reducers/homeSlider";

import QuestionsList from "./reducers/question";
import nameValue from "./reducers/question";
import has_moreFalse from "./reducers/question";
import QuestionsAnswer from "./reducers/question";
import tricheCustomer from "./reducers/triche";
const rootReducer = combineReducers({
  ui: isLoading,
  loader,
  tricheCustomer,
  loaderFooter,
  loaderSlider,
  HomeSlider,
  loaderInvi,
  forfaitList,
  userRegister,
  nameData,
  ErrAddressDate,
  ErrTelData,
  ErrNameDate,
  nomData,
  addresData,
  ErrTelDataLenght,
  loginSucces,
  loginFailed,
  ErrForfaitDate,
  loginOtpCode,
  InputOtpValid,
  InputOtpTrue,
  CodeInvalidOtp,
  OtpExpire,
  msgrrorFr,
  msgrrorAr,
  msgErrStatus,
  msgErrStatusGratuit,
  isLoadingForfait,
  startLoading,
  msgStatusTrue,
  msgTrueGratuit,
  allData,
  resFalseFr,
  resFalseAr,
  loginJaw,
  msgTrueGratuitFr,
  userList,
  editDataProfil,
  forfaitId,
  QuestionsList,
  QuestionsAnswer,
  nameValue,
  has_moreFalse,
});

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["ui"],
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(
  persistedReducer,
  applyMiddleware(thunk)

  // composeWithDevTools(applyMiddleware(thunk))
);

export const persistor = persistStore(store);
