import {USER_REGISTER , NAME_DATA, TEL_DATA, ERR_NAME_DATA, ADDRESS_DATA , NOM_DATA , ADDRESS_DATA_ADDRESS, ERR_TEL_LENGHT, ERR_FORFAIT_Date, FORFAIT_Date} from "../actions/actionsType";

const initialState = {
    userRegister:"",
    nameData:"",
    nomData:"",
    addresData:"",
    ErrAddressDate:"",
    ErrTelData:"",
    ErrNameDate:"",
    ErrTelDataLenght:"",
    ErrForfaitDate:"",
    forfaitData:""

  };
  const reducer = (state = initialState, action) => {
    switch (action.type) {
      case USER_REGISTER:
        return {
          ...state,
          userRegister: action.userRegister
        };
        case NAME_DATA:
          return {
            ...state,
            nameData: action.nameData
          };
          case NOM_DATA:
            return {
              ...state,
              nomData: action.nomData
            };
            case ADDRESS_DATA_ADDRESS:
              return {
                ...state,
                addresData: action.addresData
              };
              case ERR_TEL_LENGHT :
                return {
                  ...state,
                  ErrTelDataLenght: action.ErrTelDataLenght
                }
          case TEL_DATA:
            return {
              ...state,
              ErrTelData: action.ErrTelData
            };
            case ERR_NAME_DATA:
              return {
                ...state,
                ErrNameDate: action.ErrNameDate
              };
              case ADDRESS_DATA:
                return {
                  ...state,
                  ErrAddressDate: action.ErrAddressDate
                };
                case ERR_FORFAIT_Date : 
                return {
                  ...state,
                  ErrForfaitDate: action.ErrForfaitDate
                };
                case FORFAIT_Date :
                  return {
                    ...state,
                    forfaitData: action.forfaitData
                  }
                
     ;
      default:
        return state;
    }
  };
  export default reducer;