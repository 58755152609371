import React from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import { Trans } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import ImageSlider from "../../assets/pub.jpg";
import logo from "../../assets/logo.svg";
import robot_start from "../../assets/robot_start.png";
import socketServer from "../../store/actions/utils/socketServer";
import { GetmodifLogin, getQuestion, getHomeSlider } from "../../store/actions";
import history from "../../history";
import CircularProgress from "@material-ui/core/CircularProgress";
import TagManager from "react-gtm-module";
import { Player } from "video-react";

import "./home.css";
let app = "";
class home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      chatListUsers: "",
      langue: localStorage.getItem("i18nextLng"),
      id: Math.random().toString(16).slice(2),
    };
  }
  componentDidMount() {
    var token = localStorage.getItem("token");
    var mobile = localStorage.getItem("num");
    var have_forfait = localStorage.getItem("forfait");
    var app = localStorage.getItem("idClient");
    if (app == "" || app == null) {
      localStorage.setItem("idClient", this.state.id);
    }
    var app = localStorage.getItem("idClient");

    if (
      (mobile != null || mobile != "") &&
      (token != null || token != "") &&
      app != ""
    ) {
      socketServer.verifUserDesinscription({ mobile: mobile }, app);
      socketServer.eventEmitter.on(
        "desinscription-user-verif-quiz",
        this.UserDesinscription,
      );
    }

    if (
      (token === null || token === "") &&
      (mobile === null || mobile === "") &&
      (have_forfait === null || have_forfait === "" || have_forfait == "false")
    ) {
      history.push("/");
    } else if (
      token !== null &&
      token !== "" &&
      mobile !== null &&
      mobile !== "" &&
      have_forfait !== null &&
      have_forfait !== "" &&
      have_forfait == "false"
    ) {
      history.push("/forfait");
    }
    socketServer.verifUserLang({ mobile: mobile }, app);
    socketServer.eventEmitter.on("lang-user-quiz-response", this.UserLang);

    this.props.fetchDataLogin();
    this.props.getHomeSliderAcceuil();
  }
  componentWillUnmount() {
    //socketServer.socket.off("desinscription-user-verif-quiz");
  }
  UserLang = (socketResponse) => {
    var len = localStorage.getItem("i18nextLng");
    var lang = socketResponse.lang_app;
    if (len !== lang) {
      localStorage.setItem("i18nextLng", socketResponse.lang_app);
    }
  };

  UserDesinscription = (socketResponse) => {
    if (
      socketResponse !== undefined &&
      socketResponse.have_forfait !== undefined &&
      socketResponse.have_forfait === false
    ) {
      localStorage.setItem("forfait", socketResponse.have_forfait);
      if (socketResponse.desinscription === true) {
        history.push("/forfait");
      } else if (socketResponse.faute === true) {
        history.push("/endQuiz");
      } else if (socketResponse.finquiz === true) {
        history.push("/finQuiz");
      }
    }
  };
  componentWillReceiveProps(prevprops) {
    if (this.props.msgStatusTrue !== prevprops.msgStatusTrue) {
      localStorage.setItem("forfait", true);
      history.push("/accueil");
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.userList !== prevProps.userList) {
      this.setState({
        forfait_id: this.props.userList.forfait_id,
        id_GA: this.props.userList.id_GA,
      });

      localStorage.setItem("id_ga", this.props.userList.id_GA);
    }
  }
  goTo = () => {
    const filter = {
      sort: "name",
    };

    this.props.fetchData(filter);
  };
  render() {
    const { langue } = this.state;
    const testLang = langue === "fr";
    let x = localStorage.getItem("id_ga");
    const tagManagerArgs = {
      gtmId: process.env.REACT_APP_IDGTM,

      dataLayer: {
        userId: x,
        userProject: "Home_page",
        event: "GAID_Event",
      },
    };
    TagManager.initialize(tagManagerArgs);
    var settings = {
      dots: false,
      overflow: "hidden",
      arrows: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    };

    return (
      <div className="home2" id="divHomePage">
        <div style={{ padding: "22px", display: "flex", marginBottom: "10px" }}>
          <div className="logoStart">
            <img src={logo} alt="description " />
            <br />
          </div>
        </div>

        <div className="divSlider">
          {this.props.loaderSlider && (
            <CircularProgress
              size={20}
              style={{
                color: "#FF9D00",
                marginRight: "50%",
                marginLeft: "50%",
              }}
            />
          )}
          <Slider {...settings}>
            {this.props.HomeSlider &&
              this.props.HomeSlider.length !== 0 &&
              this.props.HomeSlider.map((categorie, index) =>
                categorie.type === "image" ? (
                  <div key={index}>
                    <img
                      src={categorie.url}
                      alt="description"
                      className="ImgSlider"
                    />
                  </div>
                ) : (
                  <Player key={index}>
                    <source src={categorie.url} />
                  </Player>
                ),
              )}
          </Slider>
        </div>
        <div className="pointTotal">
          <p className={"pointTAcF"}>
            <Trans i18nKey="Point_Total" /> {this.props.userList.pointsTotal}
          </p>
        </div>
        <div className={testLang ? "divFooter" : "divFooterAr"}>
          <div id="footer">
            <div id="top">
              <img src={robot_start} alt="description" />
            </div>
            <div id="middle">
              <p className="labelQuizInfo">
                <Trans i18nKey="quizinfo" />
              </p>
              {testLang && (
                <p className="labelInfo1">
                  <Trans i18nKey="info" />
                </p>
              )}
            </div>
            <div className="divBottom">
              <Link
                to="/quiz"
                style={{ textDecoration: "none" }}
                id="participerQuizBtn"
              >
                <div id="participerQuizBtn2">
                  <p className={"btnParticiper"} id="participerQuizBtn3">
                    <Trans i18nKey="Participer" />
                  </p>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    isLoading: state.ui.isLoading,
    userList: state.userList.userList,
    QuestionsList: state.QuestionsList.QuestionsList,
    HomeSlider: state.HomeSlider.HomeSlider,
    loaderSlider: state.ui.loaderSlider,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  fetchDataLogin: () => {
    dispatch(GetmodifLogin());
  },
  getHomeSliderAcceuil: () => {
    dispatch(getHomeSlider());
  },
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(home);
