import {HOME_SLIDER} from "../actions/actionsType";

const initialState = {
    HomeSlider:[]
  };
  const reducer = (state = initialState, action) => {
    switch (action.type) {
      case HOME_SLIDER:
        return {
          ...state,
          HomeSlider: action.HomeSlider
        };
     ;
      default:
        return state;
    }
  };
  export default reducer;