import { uiStartLoading, uiStopLoading } from "./index";
import {
  LOGIN_INPUT_SUCCES,
  LOGIN_INPUT_TRUE,
  CODE_INVALID_OTP,
  OTP_EXPIRE,
} from "./actionsType";
import history from "../../history";
import i18n from "../../i18n";
export const loginOtpCodeInput = (data) => {
  return (dispatch) => {
    dispatch(uiStartLoading());
    fetch(`${process.env.REACT_APP_DOMAIN}/ajax/quiz/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        mobile: data.mobile,
        otpCodeInput: data.otpCodeInput,
        otpCodeChif: data.otpCodeChif,
      }),
    })
      .catch((err) => {
        dispatch(uiStopLoading());
      })
      .then((res) => res.json())
      .then((res) => {
        dispatch(uiStopLoading());
        if (res.status === true && res.message === "success") {
          var id = Math.random().toString(16).slice(2);
          localStorage.setItem("token", res.token);
          localStorage.setItem("forfait", res.have_forfait);
          localStorage.setItem("idClient", id);

          i18n.changeLanguage(res.lang_app);

          // sessionStorage.clear();
          if (res.have_forfait) {
            history.push("/home");

            // window.location.assign("/home");
          } else {
            history.push("/forfait");

            // window.location.assign("/forfait");
          }

          dispatch(InputOtpTrue(res.status));
          dispatch(InputOtpValid(res.data));
        }
        if (res.status === false && res.message === "code invalid") {
          dispatch(CodeInvalidOtp(res.status));
          //sessionStorage.clear();
        }
        if (res.status === false && res.messageEN === "timeout expired") {
          dispatch(OtpExpire(res.status));
          sessionStorage.clear();
        }
      });
  };
};
export const InputOtpValid = (value) => {
  return {
    type: LOGIN_INPUT_SUCCES,
    InputOtpValid: value,
  };
};
export const InputOtpTrue = (value) => {
  return {
    type: LOGIN_INPUT_TRUE,
    InputOtpTrue: value,
  };
};
export const CodeInvalidOtp = (value) => {
  return {
    type: CODE_INVALID_OTP,
    CodeInvalidOtp: value,
  };
};
export const OtpExpire = (value) => {
  return {
    type: OTP_EXPIRE,
    OtpExpire: value,
  };
};
