import React from "react";
import logo from "../../assets/logo-jawhara-mobile.svg";
import logott from "../../assets/logott.svg";
import { Trans } from "react-i18next";
import "./login.css";
import history from "../../history";
import { connect } from "react-redux";
import { compose } from "redux";
import { nameValue, loginOtp } from "../../store/actions";
import i18n from "../../i18n";
import LoginJaw from "./popUp";
import CircularProgress from "@material-ui/core/CircularProgress";

class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      mobile: "",
      open: false,
      isLoading: false,
      id: Math.random().toString(16).slice(2),
      langue: localStorage.getItem("i18nextLng"),
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    const dataLayer = window.dataLayer || [];
    dataLayer.push({ event: "pageview" });
    var token = localStorage.getItem("token");

    var mobile = localStorage.getItem("num");
    var have_forfait = localStorage.getItem("forfait");

    if (
      token !== null &&
      token !== "" &&
      mobile !== null &&
      mobile !== "" &&
      have_forfait !== null &&
      have_forfait !== "" &&
      have_forfait == "true"
    ) {
      history.push("/home");
    } else if (
      token !== null &&
      token !== "" &&
      mobile !== null &&
      mobile !== "" &&
      have_forfait !== null &&
      have_forfait !== "" &&
      have_forfait == "false"
    ) {
      history.push("/forfait");
    }
  }

  componentWillReceiveProps(prevProps) {
    if (prevProps.loginSucces) {
      history.push("/loginOTP");
      localStorage.setItem("num", this.state.mobile);
      var app = localStorage.getItem("idClient");
      if (app == "" || app == null) {
        localStorage.setItem("idClient", this.state.id);
      }
    } else {
      this.setState({ open: true });
    }
  }

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      mobile: this.state.mobile,
    };
    this.props.getOTP(data);
  };

  changeLangue = async () => {
    var langue = this.state.langue;
    this.setState({ isLoading: true });
    if (langue == "fr") {
      await i18n.changeLanguage("ar");
    } else {
      await i18n.changeLanguage("fr");
    }
    this.setState({
      isLoading: false,
    });
    // window.location.assign("/");
  };

  render() {
    const { langue } = this.state;
    const testLang = langue === "fr";

    return (
      <div className="home" id="divLogin">
        {langue === "fr" ? (
          <div className="langueLogin">
            <p className="frLogin">Français</p>
            <p className="separLang">.</p>
            <p className="arLogin" onClick={this.changeLangue}>
              {" "}
              العربية
            </p>
          </div>
        ) : (
          <div className="langueLogin">
            <p className="frLoginFr" onClick={this.changeLangue}>
              Français
            </p>
            <p className="separLang">.</p>
            <p className="arLogin"> العربية</p>
          </div>
        )}
        <div className="imgLogoLogin">
          <img src={logo} alt="description " />
        </div>
        {this.state.isLoading && (
          <CircularProgress
            size={20}
            style={{ color: "#FF9D00", marginRight: "50%", marginLeft: "50%" }}
          />
        )}
        {!this.state.isLoading && (
          <>
            <div className="logott" dir={"ltr"}>
              <h2 className={"logitttext"}>
                <Trans i18nKey="Bytt" />
              </h2>
              <img src={logott} alt="logo tt " style={{ width: "20%" }} />
            </div>
            <div className="DivBienLangue">
              <p
                className={testLang ? "BienvenueJawhara" : "BienvenueJawharaAr"}
              >
                <Trans i18nKey="bienvenue" />
              </p>
              <p
                className={
                  testLang ? "BienvenueJawhara2" : "BienvenueJawharaAr2"
                }
              >
                <Trans i18nKey="playwithtt" />
              </p>
              <p className={testLang ? "labelSaisie" : "labelSaisieAr"}>
                <Trans i18nKey="Saissi" />
              </p>
            </div>
            <div className="form">
              <form onSubmit={this.handleSubmit}>
                <div className="divInput">
                  <p className={langue === "fr" ? "spanInput" : "spanInputAr"}>
                    +216
                  </p>
                  <input
                    className={testLang ? "inputTel" : "inputTelAr"}
                    id="mobile"
                    name="mobile"
                    type="tel"
                    required
                    pattern="[0-9]{2}[0-9]{3}[0-9]{3}"
                    autoComplete="mobile"
                    maxLength="8"
                    step="0.01"
                    onInput={(e) => {
                      if (!e.target.value) {
                        e.target.value = "";
                      } else {
                        if (parseInt(e.target.value)) {
                          e.target.value = Math.max(0, parseInt(e.target.value))
                            .toString()
                            .slice(0, 8);
                        } else {
                          e.target.value = "";
                        }
                      }
                    }}
                    value={this.state.mobile}
                    onChange={this.handleChange("mobile")}
                  ></input>
                </div>
                <div className="divBouton">
                  <button
                    className={testLang ? "btn" : "btnAr"}
                    type="submit"
                    id="loginBtn"
                  >
                    <Trans i18nKey="Suivant" />
                  </button>
                </div>
                {this.props.isLoading && (
                  <spam
                    style={{
                      display: "flex",
                      width: "332px",
                      justifyContent: "space-around",
                      alignItems: "baseline",
                    }}
                  >
                    <span class="CeC">
                      <Trans i18nKey="Chargement" />
                    </span>
                    <div class="point"></div>
                    <div class="point1"></div>
                    <div class="point2"></div>
                  </spam>
                )}
                <p className="text-center text-white">
                  {" "}
                  <Trans i18nKey="footertexttt_homepage" />
                </p>
                {this.props.loginJaw === false && (
                  <LoginJaw
                    lang={localStorage.getItem("i18nextLng") === "fr"}
                  />
                )}
              </form>
            </div>
          </>
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    isLoading: state.ui.isLoading,
    loginSucces: state.loginSucces.loginSucces,
    loginJaw: state.loginJaw.loginJaw,
  };
};
const mapDispatchToProps = (dispatch, ownProps) => ({
  getOTP: (data) => {
    dispatch(loginOtp(data));
  },
});
export default compose(connect(mapStateToProps, mapDispatchToProps))(Login);
