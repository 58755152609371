import { uiStartLoading, uiStopLoading } from "./index";
import queryString, { stringify } from "query-string";
import {
  QUESTIONS_ANSWER,
  QUESTIONS_LIST,
  NAME_VALUE,
  HAS_MORE_FALSE,
  MSG_VERIF,
} from "./actionsType";
import history from "../../history";

export const getQuestion = () => {
  return (dispatch) => {
    var token = localStorage.getItem("token");

    dispatch(uiStartLoading());
    fetch(`${process.env.REACT_APP_DOMAIN}/ajax/quiz/questions`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-access-token": token,
      },
    })
      .catch((err) => {
        dispatch(uiStopLoading());
      })
      .then((res) => res.json())
      .then((res) => {
        dispatch(uiStopLoading());
        if (res.status === true) {
          dispatch(QuestionsList(res.question));
          dispatch(QuestionsAnswer(res));
        } else if (res.status === false) {
          if (res.message === "Pas de question dans bdd") {
            history.push("/finQuiz");
          } else if (res.message === "Vous n'avez pas accès") {
            history.push("/forfait");
          } else {
            localStorage.clear();
            sessionStorage.clear();
            history.push("/");
          }
        }
      });
  };
};

export const VerifQuestion = (data) => {
  return (dispatch) => {
    var token = localStorage.getItem("token");

    dispatch(uiStartLoading());
    fetch(`${process.env.REACT_APP_DOMAIN}/ajax/quiz/questions/note`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify(data),
    })
      .catch((err) => {
        dispatch(uiStopLoading());
      })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        dispatch(uiStopLoading());
        if (res.status === true) {
          if (!res.statusQuestion && res.message === "Fin quiz") {
            history.push("/endQuiz");
          } else {
            dispatch(QuestionsAnswer(res));
            history.push("/checkQuiz");
          }
        } else if (res.status === false) {
          if (res.message === "Vous n'avez pas accès") {
            history.push("/forfait");
          } else {
            localStorage.clear();
            sessionStorage.clear();
            history.push("/");
          }
        }
      });
  };
};

export const QuestionsAnswer = (value) => {
  return {
    type: QUESTIONS_ANSWER,
    QuestionsAnswer: value,
  };
};
export const QuestionsList = (value) => {
  return {
    type: QUESTIONS_LIST,
    QuestionsList: value,
  };
};
export const nameValue = (value) => {
  return {
    type: NAME_VALUE,
    nameValue: value,
  };
};

export const has_moreFalse = (value) => {
  return {
    type: HAS_MORE_FALSE,
    has_moreFalse: value,
  };
};

export const MsgVerif = (value) => {
  return {
    type: MSG_VERIF,
    MsgVerif: value,
  };
};
