import {
  QUESTIONS_LIST,
  NAME_VALUE,
  HAS_MORE_FALSE,
  QUESTIONS_ANSWER,
  MSG_VERIF,
} from "../actions/actionsType";

const initialState = {
  QuestionsList: {},
  has_moreFalse: "",
  nameValue: "",
  QuestionsAnswer: {},
  MsgVerif: "",
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case QUESTIONS_LIST:
      return {
        ...state,
        QuestionsList: action.QuestionsList,
      };
    case QUESTIONS_ANSWER:
      return {
        ...state,
        QuestionsAnswer: action.QuestionsAnswer,
      };
    case HAS_MORE_FALSE:
      return {
        ...state,
        has_moreFalse: action.has_moreFalse,
      };
    case NAME_VALUE:
      return {
        ...state,
        nameValue: action.nameValue,
      };
    case MSG_VERIF:
      return {
        ...state,
        MsgVerif: action.MsgVerif,
      };
    default:
      return state;
  }
};
export default reducer;
