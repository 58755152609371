import { uiStartLoading, uiStopLoading } from "./index";
import { TRICHE_CUSTOMER } from "./actionsType";
import history from "../../history";

export const CustomerTriche = () => {
  var token = localStorage.getItem("token");
  return (dispatch) => {
    dispatch(uiStartLoading());
    fetch(`${process.env.REACT_APP_DOMAIN}/ajax/customer-quiz-triche`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
    })
      .catch((err) => {
        dispatch(uiStopLoading());
      })
      .then((res) => res.json())
      .then((res) => {
        dispatch(uiStopLoading());
        if (res.message === "token_error") {
          localStorage.clear();
          sessionStorage.clear();
          history.push("/");
        }
        dispatch(tricheCustomer(res));
      });
  };
};
export const tricheCustomer = (value) => {
  return {
    type: TRICHE_CUSTOMER,
    tricheCustomer: value,
  };
};
