import { UI_START_LOADING, UI_STOP_LOADING } from './actionsType';
import { UI_START_LOADER, UI_STOP_LOADER } from './actionsType';
import {UI_START_INVI, UI_STOP_INVI} from  './actionsType';
import {UI_START, UI_STOP} from "./actionsType"
import {UI_START_SLIDER, UI_STOP_SLIDER} from  './actionsType';
import {UI_START_FOOTER, UI_STOP_FOOTER} from './actionsType'
export const uiStartLoading = () => {
    return {
        type: UI_START_LOADING
    };
};
export const uiStopLoading = () => {
    return {
        type: UI_STOP_LOADING
    };
};
export const uiStartLoader = () => {
    return {
        type: UI_START_LOADER
    };
};
export const uiStopLoader = () => {
    return {
        type: UI_STOP_LOADER
    };
};

export const uiStartLoaderInvi = () => {
    return {
        type: UI_START_INVI
    };
};
export const uiStopLoaderInvi = () => {
    return {
        type: UI_STOP_INVI
    };
};

export const uiStart = () => {
    return {
        type: UI_START
    };
};

export const uiStop = () => {
    return {
        type: UI_STOP
    };
};
export const uiStartLoaderSlider = () => {
    return {
        type: UI_START_SLIDER
    };
};

export const uiStopLoaderSlider = () => {
    return {
        type: UI_STOP_SLIDER
    };
};

export const uiStartLoaderFooter = () => {
    return {
        type: UI_START_FOOTER
    };
};

export const uiStopLoaderFooter = () => {
    return {
        type: UI_STOP_FOOTER
    };
};