import { UI_START_LOADING, UI_STOP_LOADING } from "../actions/actionsType";
import { UI_START_LOADER, UI_STOP_LOADER } from "../actions/actionsType";
import  {UI_START_INVI, UI_STOP_INVI} from  '../actions/actionsType';
import {UI_START, UI_STOP} from   '../actions/actionsType';
import {UI_START_SLIDER, UI_STOP_SLIDER} from "../actions/actionsType"
import {UI_START_FOOTER, UI_STOP_FOOTER} from "../actions/actionsType"

const initialState = {
  isLoading: false,
  isLoader: false,
  loaderInvi:false,
  loader:false,
  loaderSlider:false,
  loaderFooter:false
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case UI_START_LOADING:
      return {
        ...state,
        isLoading: true
      };
    case UI_STOP_LOADING:
      return {
        ...state,
        isLoading: false
      };
    case UI_START_LOADER:
      return {
        ...state,
        isLoader: true
      };
    case UI_STOP_LOADER:
      return {
        ...state,
        isLoader: false
      };
      case UI_START_INVI:
        return {
          ...state,
          loaderInvi: true
        };
        case UI_STOP_INVI:
          return {
            ...state,
            loaderInvi: false
          };
          case UI_START:
            return {
              ...state,
              loader: true
            };
          case UI_STOP:
            return {
              ...state,
              loader: false
            };
            case UI_START_SLIDER:
              return {
                ...state,
                loaderSlider: true
              };
              case UI_STOP_SLIDER:
                return {
                  ...state,
                  loaderSlider: false
                };
  
                case UI_START_FOOTER:
                  return {
                    ...state,
                    loaderFooter: true
                  }; 
                  case UI_STOP_FOOTER:
                  return {
                    ...state,
                    loaderFooter: false
                  }; 
        
  
    default:
      return state;
  }
};
export default reducer;