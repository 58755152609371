/** @format */

import React from "react";
import "./endQuiz.css";
import history from "../../history";
import { connect } from "react-redux";
import { compose } from "redux";
import { getQuestion, VerifQuestion, GetmodifLogin } from "../../store/actions";
import socketServer from "../../store/actions/utils/socketServer";
import { addForfait, msgErrStatus, msgStatusTrue } from "../../store/actions";
import { Trans } from "react-i18next";
import robot_sad from "../../assets/robot_sad.png";
import Pop from "../forfait/popUp";
import PopError1 from "../forfait/popupError1";
import PopError2 from "../forfait/popupError2";
import i18n from "../../i18n";
import TagManager from "react-gtm-module";

class EndQuiz extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      forfait: "none",
      openForfait: false,
      open: false,
      errStatusL: true,
      langue: localStorage.getItem("i18nextLng"),
      id: Math.random().toString(16).slice(2),
      isDisabled: false,
    };
  }

  componentDidMount() {
    const dataLayer = window.dataLayer || [];
    dataLayer.push({ event: "pageview" });
    var app = localStorage.getItem("idClient");
    if (app == "" || app == null) {
      localStorage.setItem("idClient", this.state.id);
    }
    var token = localStorage.getItem("token");
    var mobile = localStorage.getItem("num");
    var have_forfait = localStorage.getItem("forfait");
    var app = localStorage.getItem("idClient");

    if (
      (mobile != null || mobile != "") &&
      (token != null || token != "") &&
      app != ""
    ) {
      socketServer.verifUserDesinscription({ mobile: mobile }, app);
      socketServer.eventEmitter.on(
        "desinscription-user-verif-quiz",
        this.UserDesinscription,
      );
    }

    if (
      (token === null || token === "") &&
      (mobile === null || mobile === "") &&
      (have_forfait === null || have_forfait === "" || have_forfait == "false")
    ) {
      history.push("/");
    }
    if (
      token !== null &&
      token !== "" &&
      mobile !== null &&
      mobile !== "" &&
      have_forfait !== null &&
      have_forfait !== "" &&
      have_forfait == "true"
    ) {
      history.push("/home");
    }
    socketServer.verifUserLang({ mobile: mobile }, app);
    socketServer.eventEmitter.on("lang-user-quiz-response", this.UserLang);
  }
  componentWillReceiveProps(prevprops) {
    if (this.props.msgStatusTrue !== prevprops.msgStatusTrue) {
      localStorage.setItem("forfait", true);
      history.push("/home");
    }
  }
  componentWillUnmount() {
    // socketServer.socket.off("desinscription-user-verif-quiz");
  }
  UserLang = (socketResponse) => {
    var len = localStorage.getItem("i18nextLng");
    var lang = socketResponse.lang_app;
    if (len !== lang) {
      localStorage.setItem("i18nextLng", socketResponse.lang_app);
    }
  };
  UserDesinscription = (socketResponse) => {
    if (
      socketResponse !== undefined &&
      socketResponse.have_forfait !== undefined &&
      socketResponse.have_forfait == true
    ) {
      localStorage.setItem("forfait", socketResponse.have_forfait);
      // history.push("/");
    }
  };
  componentWillMount() {
    this.props.fetchError(true);
    this.props.fetchStatusTrue(false);
  }

  async SelectForfaitChange(value) {
    await this.setState({ forfait: value });
  }

  goTo = () => {
    history.goBack();
  };

  deconnecter = () => {
    var mobile = localStorage.getItem("num");
    var app = localStorage.getItem("idClient");
    var langue = this.state.langue;

    const data = {
      mobile: mobile,
    };

    // socketServer.socket.off("desinscription-user-verif-quiz")
    // socketServer.logout(data, app);

    localStorage.clear();
    sessionStorage.clear();

    localStorage.setItem("i18nextLng", langue);

    history.push("/");
  };

  ValidForfait = () => {
    var mobile = localStorage.getItem("num");
    if (mobile === null) {
      history.push("/");
    } else {
      const data = {
        mobile: mobile,
        achatPack: true,
      };
      this.props.ChoisirFofait(data);
    }
  };

  render() {
    const { langue } = this.state;
    const testLang = langue === "fr";
    let x = localStorage.getItem("id_ga");
    const tagManagerArgs = {
      gtmId: process.env.REACT_APP_IDGTM,

      dataLayer: {
        userId: x,
        userProject: "Oops_Quiz",
        event: "GAID_Event",
      },
    };
    TagManager.initialize(tagManagerArgs);
    return (
      <div className="home">
        <div
          style={{
            backgroundColor: "#ffffff",
            borderRadius: "25px",
            position: "absolute",
            marginTop: "12px",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <div className="imgLogoEnd">
            <p className="oops">
              <Trans i18nKey="Oops" />
            </p>
          </div>

          <div className="fautecontain">
            <p className="faute">
              <Trans i18nKey="Faute" />
            </p>
          </div>

          <div className="div5Profil">
            <p className="scoreF">
              <Trans i18nKey="Score" />
            </p>
          </div>
          <div className="div5Profil1">
            <p className="questionBp">{this.props.QuestionsAnswer.pointsAct}</p>
          </div>
          <div
            style={{
              justifyContent: "center",
              display: "flex",
              paddingTop: "10px",
            }}
          >
            <img
              src={robot_sad}
              style={{ height: "149px", width: "84px" }}
              alt="description"
            />
          </div>
          <div className="div5Profil">
            <p className="ressayerF">
              <Trans i18nKey="Ressayer1" />
            </p>
          </div>
          <div className="div5Profil5">
            <p className="ressayerF">
              <Trans i18nKey="Ressayer2" />
            </p>
          </div>
          <div className="div5Profil2">
            <p className="ressayerF">
              <Trans i18nKey="Ressayer3" />
            </p>
          </div>
          <div className="divBouton21">
            <button
              className="btn"
              disabled={this.props.isLoadingForfait}
              onClick={this.ValidForfait}
              id="achatOopsForfaitBtn"
              // type="submit"
            >
              <Trans i18nKey="acheter" />
            </button>
          </div>

          {this.props.isLoading && (
            //<CircularProgress size={20} style={{ color: "#FF9D00",   marginRight: "50%",marginLeft: "50%"} } />
            <spam
              style={{
                display: "flex",
                width: "250px",
                justifyContent: "space-around",
                alignItems: "baseline",
              }}
            >
              <p class="CeC1">
                <Trans i18nKey="Chargement" />
              </p>
              <div class="point"></div>
              <div class="point1"></div>
              <div class="point2"></div>
            </spam>
          )}
          <div className="divBouton212">
            <button
              className="btnquitterEnd"
              onClick={this.deconnecter}
              type="submit"
              id="quitOopsForfaitBtn"
            >
              <Trans i18nKey="quitter" />
            </button>
          </div>
          {this.props.msgErrStatus === false &&
            this.props.allData.message !== undefined && (
              <div>
                {this.props.allData.message ==
                  "Votre demande ne peut pas être traitée maintenant, veuillez réessayer plus tard." && (
                  <PopError1 />
                )}
                {this.props.allData.message ==
                  "Cher Client, votre solde est insuffisant." && <Pop />}

                {this.props.allData.message ==
                  "Votre demande ne peut pas être traitée , veuillez vérifier votre service ou votre abonnement internet." && (
                  <PopError2 />
                )}
              </div>
            )}
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    isLoader: state.ui.isLoader,
    isLoading: state.ui.isLoading,
    msgErrStatus: state.msgErrStatus.msgErrStatus,
    msgrrorFr: state.msgrrorFr.msgrrorFr,
    msgrrorAr: state.msgrrorAr.msgrrorAr,
    msgStatusTrue: state.msgStatusTrue.msgStatusTrue,
    userList: state.userList.userList,
    QuestionsList: state.QuestionsList.QuestionsList,
    QuestionsAnswer: state.QuestionsAnswer.QuestionsAnswer,
    allData: state.allData.allData,
    isLoadingForfait: state.isLoadingForfait.isLoadingForfait,
  };
};
const mapDispatchToProps = (dispatch, ownProps) => ({
  ChoisirFofait: (data) => dispatch(addForfait(data)),
  fetchError: (data) => dispatch(msgErrStatus(data)),
  fetchStatusTrue: (data) => dispatch(msgStatusTrue(data)),
  fetchData: (filter) => {
    const lang = localStorage.getItem("i18nextLng");
    filter.lang = lang;
    dispatch(getQuestion(filter));
  },
  fetchDataLogin: () => {
    dispatch(GetmodifLogin());
  },
  VerifData: (data) => {
    dispatch(VerifQuestion(data));
  },
});
export default compose(connect(mapStateToProps, mapDispatchToProps))(EndQuiz);
