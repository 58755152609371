import React from "react";
import AcceuilHeader from "../acceuilHeader/acceuil";
import { Trans } from "react-i18next";
import "./quiz.css";
import { connect } from "react-redux";
import { compose } from "redux";
import {
  getQuestion,
  VerifQuestion,
  CustomerTriche,
} from "../../store/actions";
import history from "../../history";
import CircularProgress from "@material-ui/core/CircularProgress";
import Timer from "./timer";
import { Link } from "react-router-dom";
import socketServer from "../../store/actions/utils/socketServer";

let app = "";
class Quiz extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      chatListUsers: "",
      selectedOption: null,
      status: false,
      index: null,
      userProfile: [],
      langue: localStorage.getItem("i18nextLng"),
      id: Math.random().toString(16).slice(2),
    };
  }
  componentDidMount() {
    const dataLayer = window.dataLayer || [];
    dataLayer.push({ event: "pageview" });
    var token = localStorage.getItem("token");
    var mobile = localStorage.getItem("num");
    var have_forfait = localStorage.getItem("forfait");
    var app = localStorage.getItem("idClient");
    if (app == "" || app == null) {
      localStorage.setItem("idClient", this.state.id);
    }

    var app = localStorage.getItem("idClient");

    if (
      (mobile != null || mobile != "") &&
      (token != null || token != "") &&
      app != ""
    ) {
      socketServer.verifUserDesinscription({ mobile: mobile }, app);
      socketServer.eventEmitter.on(
        "desinscription-user-verif-quiz",
        this.UserDesinscription
      );
    }

    if (
      (token === null || token === "") &&
      (mobile === null || mobile === "") &&
      (have_forfait === null || have_forfait === "" || have_forfait == "false")
    ) {
      history.push("/");
    }
    socketServer.verifUserLang({ mobile: mobile }, app);
    socketServer.eventEmitter.on("lang-user-quiz-response", this.UserLang);
    this.props.fetchData();
  }

  componentWillUnmount() {
    //socketServer.socket.off("desinscription-user-verif-quiz");
    history.go(1);
  }

  UserLang = (socketResponse) => {
    var len = localStorage.getItem("i18nextLng");
    var lang = socketResponse.lang_app;
    if (len !== lang) {
      localStorage.setItem("i18nextLng", socketResponse.lang_app);
    }
  };

  UserDesinscription = (socketResponse) => {
    if (
      socketResponse !== undefined &&
      socketResponse.have_forfait !== undefined &&
      socketResponse.have_forfait == false
    ) {
      localStorage.setItem("forfait", socketResponse.have_forfait);

      if (socketResponse.desinscription === true) {
        history.push("/forfait");
      } else if (socketResponse.faute === true) {
        history.push("/endQuiz");
      } else if (socketResponse.finquiz === true) {
        history.push("/finQuiz");
      }
    }
  };
  changeresponse = (item, index) => {
    this.setState({ status: true, index: index });
  };
  validate = () => {
    var responses = [];
    this.props.QuestionsList.responses.map((resp, i) => {
      responses.push({ content: resp.content, status: this.state.index === i });
    });
    const question = {
      id: this.props.QuestionsList.id,
      responses: responses,
    };
    const data = { question };
    this.props.VerifData(data);
  };
  timout = () => {
    var responses = [];
    this.props.QuestionsList.responses.map((resp) => {
      responses.push({ content: resp.content, status: false });
    });
    const question = {
      id: this.props.QuestionsList.id,
      responses: responses,
    };
    const data = { question };
    this.props.VerifData(data);
    history.push("/checkQuiz", { name: this.props.QuestionsList.question });
  };
  render() {
    var len = localStorage.getItem("i18nextLng");
    const { langue } = this.state;
    const testLang = langue === "fr";
    if (window && window.gaGlobal) {
      app = window.gaGlobal.vid;
    }
    return (
      <div className="home2">
        <div>
          <AcceuilHeader />
          {this.props.QuestionsList.question ? (
            <div className="div2Profil3">
              <Timer timout={this.timout} />
            </div>
          ) : (
            <CircularProgress
              size={20}
              style={{
                color: "#FF9D00",
                marginRight: "50%",
                marginLeft: "50%",
              }}
            />
          )}
          <div className="div2Profil">
            <p className={testLang ? "question" : "questionAr"}>
              {this.props.QuestionsList.question}
            </p>
          </div>
          {this.props.QuestionsList.responses &&
            this.props.QuestionsList.responses.map((responses, i) => (
              <div key={i}>
                <div
                  onClick={() => this.changeresponse(responses, i)}
                  className={
                    this.state.index === i ? "div3ProfilAC" : "div3Profil"
                  }
                >
                  <p
                    className={
                      this.state.index === i ? "responseACAr" : "responseAC"
                    }
                  >
                    {responses.content}
                  </p>
                </div>
              </div>
            ))}

          <div
            style={{
              justifyContent: "center",
              flexDirection: "row",
              display: "flex",
              justifyContent: "space-between",
              marginTop: "17%",
              marginbottom: "5%",
            }}
          >
            <div className={testLang ? "rongFR" : "rongAR"}>
              <div className="rongp">
                {(this.props.QuestionsAnswer.fauseQuestion == 1 ||
                  this.props.QuestionsAnswer.fauseQuestion == 2 ||
                  this.props.QuestionsAnswer.fauseQuestion == 3) && (
                  <p
                    style={{
                      color: "#D8030B",
                      fontSize: "36px",
                      fontFamily: "cairo",
                      marginTop: "-24px",
                      padding: "4px",
                    }}
                  >
                    X
                  </p>
                )}
              </div>
              <div className="rongp">
                {this.props.QuestionsAnswer.fauseQuestion == 2 && (
                  <p
                    style={{
                      color: "#D8030B",
                      fontSize: "36px",
                      fontFamily: "cairo",
                      marginTop: "-24px",
                      padding: "4px",
                    }}
                  >
                    X
                  </p>
                )}
              </div>
              <div className="rongp">
                {this.props.QuestionsAnswer.fauseQuestion == 3 && (
                  <p
                    style={{
                      color: "#D8030B",
                      fontSize: "36px",
                      fontFamily: "cairo",
                      marginTop: "-24px",
                      padding: "4px",
                    }}
                  >
                    X
                  </p>
                )}
              </div>
            </div>
            <div
              className={testLang ? "divBoutonEnvoyer" : "divBoutonEnvoyerAR"}
            >
              <button
                className={
                  this.state.index !== null ? "btnEnvoyerFR" : "btnEnvoyerAr"
                }
                onClick={this.validate}
                type="submit"
              >
                <Trans i18nKey="valider" />
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    isLoading: state.ui.isLoading,
    QuestionsList: state.QuestionsList.QuestionsList,
    QuestionsAnswer: state.QuestionsAnswer.QuestionsAnswer,
    tricheCustomer: state.tricheCustomer.tricheCustomer,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  triche: () => {
    dispatch(CustomerTriche());
  },
  fetchData: () => {
    dispatch(getQuestion());
  },
  VerifData: (data) => {
    dispatch(VerifQuestion(data));
  },
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(Quiz);
