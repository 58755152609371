import {
  FORFAIT_LIST,
  MSG_ERROR_FR,
  MSG_ERROR_AR,
  MSG_ERROR_STATUS,
  MSG_ERROR_GRATUIT,
  MSG_TRUE,
  MSG_GRATUIT_TRUE,
  ALL_DATA,
  RES_FALSE_FR,
  RES_FALSE_AR,
  MSG_TRUE_GRATUIT,
  STOP_LOADING,
  START_LOADING,
} from "../actions/actionsType";

const initialState = {
  forfaitList: "",
  msgrrorFr: "",
  msgrrorAr: "",
  msgErrStatus: true,
  msgErrStatusGratuit: true,
  msgStatusTrue: false,
  msgTrueGratuit: false,
  allData: [],
  resFalseFr: "",
  resFalseAr: "",
  msgTrueGratuitFr: "",
  isLoadingForfait: false,
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FORFAIT_LIST:
      return {
        ...state,
        forfaitList: action.forfaitList,
      };
    case START_LOADING:
      return {
        ...state,
        isLoadingForfait: true,
      };
    case STOP_LOADING:
      return {
        ...state,
        isLoadingForfait: false,
      };
    case MSG_ERROR_FR:
      return {
        ...state,
        msgrrorFr: action.msgrrorFr,
      };
    case MSG_ERROR_AR:
      return {
        ...state,
        msgrrorAr: action.msgrrorAr,
      };
    case MSG_ERROR_STATUS:
      return {
        ...state,
        msgErrStatus: action.msgErrStatus,
      };
    case MSG_ERROR_GRATUIT:
      return {
        ...state,
        msgErrStatusGratuit: action.msgErrStatusGratuit,
      };
    case MSG_TRUE:
      return {
        ...state,
        msgStatusTrue: action.msgStatusTrue,
      };
    case MSG_GRATUIT_TRUE:
      return {
        ...state,
        msgTrueGratuit: action.msgTrueGratuit,
      };
    case ALL_DATA:
      return {
        ...state,
        allData: action.allData,
      };
    case RES_FALSE_FR:
      return {
        ...state,
        resFalseFr: action.resFalseFr,
      };

    case RES_FALSE_AR:
      return {
        ...state,
        resFalseAr: action.resFalseAr,
      };

    case MSG_TRUE_GRATUIT:
      return {
        ...state,
        msgTrueGratuitFr: action.msgTrueGratuitFr,
      };
    default:
      return state;
  }
};
export default reducer;
