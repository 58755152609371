import { uiStartLoading, uiStopLoading } from "./index";
import {
  LOGIN_FAILED,
  LOGIN_SUCCESS,
  LOGIN_CODE_OTP,
  LOGIN_JAW,
} from "./actionsType";
import Cookies from "universal-cookie";
import history from "../../history";

export const loginOtp = (data) => {
  const cookies = new Cookies();

  return (dispatch) => {
    dispatch(uiStartLoading());
    dispatch(loginJaw(true));
    fetch(`${process.env.REACT_APP_DOMAIN}/ajax/quiz/loginOTP`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        mobile: data.mobile,
        lang_app: localStorage.getItem("i18nextLng"),
      }),
    })
      .catch((err) => {
        dispatch(uiStopLoading());
      })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        dispatch(uiStopLoading());
        if (
          res.status === false &&
          res.messageEN === "Customer mobile must be Jawhara Mobile"
        ) {
          dispatch(loginJaw(res.status));
        }
        if (res.status === true) {
          sessionStorage.setItem("otpCodeChif", res.otpCodeChif);
          localStorage.setItem("id_ga", res.id_ga);
          dispatch(loginSucces(res.messageEN));
        }
        if (res.status === false) {
          history.push("/");
        }
      });
  };
};
export const loginFailed = (value) => {
  return {
    type: LOGIN_FAILED,
    loginFailed: value,
  };
};
export const loginSucces = (value) => {
  return {
    type: LOGIN_SUCCESS,
    loginSucces: value,
  };
};
export const loginOtpCode = (value) => {
  return {
    type: LOGIN_CODE_OTP,
    loginOtpCode: value,
  };
};
export const loginJaw = (value) => {
  return {
    type: LOGIN_JAW,
    loginJaw: value,
  };
};
